import React, { ChangeEvent } from 'react';
import { inject, observer } from 'mobx-react';
import { CenterBanner, TopContentBar, TableLoader, NoData, CustomCheckbox, CustomFieldAdv } from 'components';
import {RouteComponentProps} from 'react-router';
import {UserStore, UIStore, PayrollStore, EmployerStore} from 'stores';

import {
  STORE_UI,
  STORE_PAYROLL,
  PaymentFrequency,
  STORE_EMPLOYER,
  STORE_USER,
  FORM_MODAL_WIDTH,
  PAYROLL_MODAL
} from 'appConstants';

import { history } from 'index';
import styles from './PayrollGroupNewContributionsPage.module.scss';
import classNames from 'classnames';
import {EmployeeContributionModel} from 'graphql/models/employeeContribution';
import { FieldArray, Formik, FormikActions, FormikProps } from 'formik';
import { toJS } from 'mobx';

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_PAYROLL]: PayrollStore;
  [STORE_EMPLOYER]: EmployerStore;
  [STORE_USER]: UserStore;
}

interface FormValuesPayroll {
  employeeListPayroll: EmployeeContributionModel[];
}

@inject(STORE_UI, STORE_PAYROLL, STORE_EMPLOYER, STORE_USER)
@observer

class PayrollGroupNewContributionsPage extends React.PureComponent<Props, {}>{

   async componentDidMount() {
     const {employerId, groupId} = this.props.match.params;
     const {username} = this.props[STORE_USER].user;
     const payrollStore = this.props[STORE_PAYROLL];
     payrollStore.clearPayrollGroup();

     payrollStore.setLoadNewContributionPage(true);

     await this.props[STORE_EMPLOYER].loadEmployerByPayrollAdminEmail(employerId, username, "employerName");
     await payrollStore.loadPayrollGroupById(groupId, "groupName");
     await payrollStore.loadEmployeeNewContributions(groupId);
     payrollStore.loadPayrollGroupTabs();

     payrollStore.setLoadNewContributionPage(false);
     this.forceUpdate();
   }

  state = {
    checked: false,
    activeTab: 'new-contributions',
    checkDirty: false
  };

  initialValues: FormValuesPayroll = {
    employeeListPayroll: []
  };

  formData: any;

  formHandleSubmit = () => {};

  formReset = () => {};

  breadCrumbs = [{title: "", linkTo: ""}];

  
  pausedChangedContributions : string[] = [];


  onTabAction = (val: string) => {
    const { groupId, employerId } = this.props.match.params;
    history.push("/payroll/"+employerId+"/group/"+groupId+"/"+val);
  };

  getFormattedAmount(amount: number, type: string) {
    return type === "Value" ? this.props[STORE_UI].getFormattedMoney(amount, "newContribution") : amount + "%";
  }

  checkboxHandler(e: ChangeEvent<Element>, form: FormikProps<FormValuesPayroll>, index: number) {
    const employeePayrollCheck = JSON.parse(JSON.stringify(form.values.employeeListPayroll.slice()));
    if (employeePayrollCheck[index].status === 'Changed' || employeePayrollCheck[index].status === 'Paused' || employeePayrollCheck[index].status === 'Registered' ) {
      form.handleChange(e);
      employeePayrollCheck[index].checker = !employeePayrollCheck[index].checker;
      
      if (employeePayrollCheck[index].checker) {
        this.pausedChangedContributions.push(employeePayrollCheck[index].id);
      } else {
        let selectedIndex = this.pausedChangedContributions.indexOf(employeePayrollCheck[index].id);
        this.pausedChangedContributions.splice(selectedIndex, 1);

      }
  }
  }

  renderCheckerField(form: FormikProps<FormValuesPayroll>, index: number) {
    const employeePayrollCheck = JSON.parse(JSON.stringify(form.values.employeeListPayroll.slice()));
    let checkerName = `employeeListPayroll[${index}].checker`;

    return (
      <td className={styles.checkboxCol}>
        <CustomFieldAdv
          title=''
          name={checkerName}
          form={form}
          component={CustomCheckbox}
          className={styles.tableFormItem}
          checked={employeePayrollCheck[index].checker || ''}
          onChange={(e) => {
            this.checkboxHandler(e, form, index)
          }}
        />
      </td>
    )
  }

  renderPayrollGroupContributionsList() {
    this.initialValues = {
      employeeListPayroll: toJS(this.props[STORE_PAYROLL].newContributionsList)
    }

    if (!this.initialValues.employeeListPayroll.length) {
      return <tbody><NoData columnSpan={7} /></tbody>
    }

    const { tabId } = this.props.match.params;
    return (
      <tbody id='header'>
        <Formik
          initialValues={this.initialValues}
          onSubmit={this.onSubmit}
          enableReinitialize={false}
        >
          {form => {
            this.formData = form;
            this.formHandleSubmit = form.handleSubmit;
            this.formReset = form.resetForm;
            
            return (
              <FieldArray name='employeeList'>
                {() => (
                  this.formData.values.employeeListPayroll.map((contribution: EmployeeContributionModel, index: number) => {
                    return (
                      <tr key={index}>
                        {this.renderCheckerField(form, index)}
                         <td>{this.props[STORE_UI].getFormattedDate(contribution.updatedAt)}</td>
                          <td>
                            <strong>{contribution.name}</strong>
                          </td>
                          <td>{contribution.nino}</td>
                          <td>{this.getFormattedAmount(contribution.amount, contribution.amountType)}</td>
                          <td>{contribution.product}</td>
                          <td>{contribution.status}</td>
                          <td>{PaymentFrequency[contribution.type]}</td>
                      </tr>
                    )
                  }
                  )
                )}
              </FieldArray>
            )
          }
          }
        </Formik>
      </tbody>
    )
  }

  checkAll() {
    this.formData.values.employeeListPayroll.forEach((item: EmployeeContributionModel, index: number) => {
    if (item.status === 'Changed' || item.status === 'Paused' || item.status === 'Registered') {
      this.formData.setFieldValue(`employeeListPayroll[${index}].checker`, !this.state.checked);
      this.pausedChangedContributions.push(item.id);
    }
    if (this.state.checked) {
      if (index > -1) {
      this.pausedChangedContributions = [];
      }
    }
    });
    this.setState({ checked: !this.state.checked });
  }

  onSubmit =async (submittedValues: FormValuesPayroll, actions: FormikActions<FormValuesPayroll>) => {}

  showPayrollGroupContributions() {
    return (
      <table className={classNames(styles.cuTable, styles.noHover)}>
        <thead>
        <tr>
          <th className={classNames(styles.checkboxCol, styles.payrollCheckbox)}>
              <CustomCheckbox
                name='checkAll'
                title=''
                className={styles.tableFormItem}
                checked={this.state.checked}
                onChange={() => {
                  this.checkAll()
                }}
              />
              <span className={styles.pausedText}>Amendments</span>
            </th>
          <th className={styles.numberCol}>Date</th>
          <th>Name</th>
          <th className={styles.numberCol}>NI Number</th>
          <th>Amount</th>
          <th>Product</th>
          <th>Activity</th>
          <th>Frequency</th>
        </tr>
        </thead>
        {this.props[STORE_PAYROLL].loadNewContributionPage ? <tbody><TableLoader columnSpan={7}/></tbody> : (
          this.renderPayrollGroupContributionsList()
        )}
      </table>
    );
  }

  getMainTitle() {
    if (this.props[STORE_EMPLOYER].currentEmployer.name && this.props[STORE_PAYROLL].payrollGroup.name) {
      return "Payroll - " + this.props[STORE_EMPLOYER].currentEmployer.name + " - " + this.props[STORE_PAYROLL].payrollGroup.name;
    }
    return "Payroll"
  };

  getFileName() {
    let fileNameData = this.props[STORE_PAYROLL].payrollGroup.name + "_" + this.props[STORE_EMPLOYER].currentEmployer.name + "_";
    fileNameData = fileNameData.split(" ").join("-").toLowerCase();
    fileNameData = fileNameData + this.props[STORE_UI].getFormattedFileDate() + ".csv";
    return "New_contributions_" + fileNameData;
  }

  downloadCSV() {

    let headersRow: string[][] = [
      ["Date", "Name", "NI Number", "Amount", "Product", "Activity", "Frequency", "Employee Id"]
    ];

    let rows = this.props[STORE_PAYROLL].newContributionsList.map((item: EmployeeContributionModel) => {
      const updatedAt = this.props[STORE_UI].getFormattedShortDate(item.updatedAt);
      const amount = this.getFormattedAmount(item.amount, item.amountType);

      return [updatedAt, item.name, item.nino, amount, item.product, item.status, PaymentFrequency[item.type], item.externalEmployeeId]
    });

    let data = headersRow.concat(rows);
    let csvFile = data.map(e => e.map(value => `"${value}"`).join(",")).join("\n");
    let filename = this.getFileName();

    let blob = new Blob(["\ufeff"+csvFile], {type: 'text/csv;charset=utf-8;'});

    if ((navigator as any).msSaveBlob) { // Use type assertion to cast navigator as any
      (navigator as any).msSaveBlob(blob, filename); // Call msSaveBlob on navigator
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  payrollModal = (e: React.MouseEvent) => {
    const { groupId } = this.props.match.params;
    e.preventDefault();
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: PAYROLL_MODAL,
      title: 'Complete Payroll cut-off',
      props: {
        payrollGroupId: groupId,
        pausedChangedContributions: this.pausedChangedContributions,
      }
    });
  };

  render() {
    this.breadCrumbs = [
      {
        title: this.props[STORE_EMPLOYER].currentEmployer.name,
        linkTo: "/payroll/" + this.props[STORE_EMPLOYER].currentEmployer.id + "/groups"
      },
      {title: this.props[STORE_PAYROLL].payrollGroup.name, linkTo: ""},
    ];

    return (
      <>
        <CenterBanner
          homeUrl={this.props[STORE_USER].homeUrl}
          mainTitle={this.getMainTitle()}
          tabs={this.props[STORE_PAYROLL].paymentTabs}
          activeTab={"new-contributions"}
          onClickTab={this.onTabAction}
          breadCrumbs={this.breadCrumbs}
        />
        <div className={styles.cContent}>
          <TopContentBar
            mainTitle={"New contributions123"}
            actionButtonNameSecond={"Complete payroll cutoff"}
            actionButtonName={this.props[STORE_PAYROLL].loadNewContributionPage || !this.props[STORE_PAYROLL].newContributionsList.length ? "" : "Download .csv"}
            actionButtonEvent={(e) => {
              e.preventDefault();
              this.downloadCSV()
            }}
            actionButtonSecondEvent={this.payrollModal}
          /><span>
          </span>
          {this.showPayrollGroupContributions()}
        </div>
      </>
    );
  }
}

export default PayrollGroupNewContributionsPage;
